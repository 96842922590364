import { KeyValueModel } from "model/api";
import { EnumDistaciaCardapio } from "model/enums/enum-distancia-cardapio";

export const PerimetroAtendimentoPresencialMock: KeyValueModel[] = [
    new KeyValueModel(EnumDistaciaCardapio.NaoControla, "Não Controla"),
    new KeyValueModel(EnumDistaciaCardapio.DEZ_METROS, "10 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.VINTE_METROS, "20 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.TRINTA_METROS, "30 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.QUARENTA_METROS, "40 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.CINQUENTA_MENTROS, "50 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.CEM_METROS, "100 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.DUZENTOS_METROS, "200 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.TREZENTOS_METROS, "300 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.QUATROZENTOS_METROS, "400 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.QUINHENTOS_METROS, "500 Metros"),
    new KeyValueModel(EnumDistaciaCardapio.UM_QUILOMETRO, "1 KM"),
    new KeyValueModel(EnumDistaciaCardapio.CINCO_QUILOMETROS, "5 KM"),
    new KeyValueModel(EnumDistaciaCardapio.CINQUENTA_QUILOMETROS, "50 KM"),
    new KeyValueModel(EnumDistaciaCardapio.CEM_QUILOMETROS, "100 KM"),
];