import { useCallback } from 'react';
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { DepositoHeader } from "./components/deposito-header/deposito-header";
import { DepositoList } from "./components/deposito-list/deposito-list";
import { ButtonFab } from "views/components/controles";
import { DepositoAddIcon } from "views/components/icons";
import { Grid } from "@material-ui/core";
import { useCadastros } from 'services/app';

export const DepositoPage = () => {

  const classes = useDefaultCadastroStyles();
  const { abrirCadastroDepositos } = useCadastros();
  const onClickAdd = useCallback(() => {
    abrirCadastroDepositos('', "", true);
  }, [abrirCadastroDepositos]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <DepositoHeader />
      </Grid>
      <Grid className={classes.list}>
        <DepositoList />
      </Grid>
      <ButtonFab tooltip="Adicionar Depósito" icon={<DepositoAddIcon tipo="BUTTON_FAB" />} onClick={onClickAdd} />
    </Grid>
  );
};

export default DepositoPage;
